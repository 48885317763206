
import Vue from 'vue'
import {
  sermonRootUrl,
  siteScriptureUrl,
  siteSermonDateUrl,
} from '~/assets/ts/utils/urls'
import SiteNavGroupSubnav from '~/components/site/nav/group/Subnav.vue'
import SiteNavLi from '~/components/site/nav/Li.vue'

export default Vue.extend({
  name: 'SiteNavGroupSermons',
  components: { SiteNavLi, SiteNavGroupSubnav },
  computed: {
    scriptureUrl(): string {
      return siteScriptureUrl()
    },
    sermonsUrl(): string {
      return sermonRootUrl
    },
    sermonsDateUrl(): string {
      return siteSermonDateUrl()
    },
    staffPicksUrl(): string {
      return `${this.sermonsUrl}/staff-picks`
    },
    featuredUrl(): string {
      return `${this.sermonsUrl}/featured`
    },
    videosUrl(): string {
      return `${this.sermonsUrl}/videos`
    },
    clipsUrl(): string {
      return `${this.sermonsUrl}/clips`
    },
    transcriptsUrl(): string {
      return `${this.sermonsUrl}/transcripts`
    },
  },
  methods: {
    navigate() {
      this.$emit('navigate')
    },
  },
})
