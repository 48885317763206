
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import { routeMatch } from '~/assets/ts/utils/urls'
import SaIcon from '~/components/_general/SaIcon.vue'
import NavLink from '~/components/_general/NavLink.vue'

export default Vue.extend({
  name: 'SiteNavLi',
  components: { SaIcon },
  inheritAttrs: false,
  props: {
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    exact: {
      type: Boolean,
    },
    prevent: {
      type: Boolean,
    },
    addedActiveMatch: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    excludeActiveMatch: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    hrefSameTarget: {
      type: Boolean,
    },
  },
  computed: {
    componentOptions(): Record<string, any> {
      if (this.to) {
        return {
          exact: this.exact,
          to: this.to,
          activeClasses: this.activeClasses,
          excludeActiveMatch: this.excludeActiveMatch,
        }
      } else if (this.href) {
        return {
          href: this.href,
          target: this.hrefSameTarget ? undefined : '_blank',
        }
      }
      return {}
    },
    activeClasses(): string {
      return `bg-theme nav-li`
    },
    groupActiveClasses(): string {
      if (!this.isGroup && !this.addedActiveMatch && !this.excludeActiveMatch)
        return ''
      if (
        routeMatch(this, this.excludeActiveMatch, this.exact) ||
        !routeMatch(this, this.addedActiveMatch, this.exact)
      ) {
        return ''
      } else {
        return this.activeClasses
      }
    },
    key(): string {
      return this.to ?? this.componentType
    },
    componentType(): any {
      if (this.isGroup || this.prevent) {
        return 'button'
      } else if (this.to) {
        return NavLink
      } else {
        return 'a'
      }
    },
    isGroup(): boolean {
      return !this.href && !this.to
    },
  },
})
