
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SaIconsType } from '~/assets/ts/types/icons'
import SiteNavGroupHeader from '~/components/site/nav/group/Header.vue'
import SiteNavGroupBack from '~/components/site/nav/group/Back.vue'

export default Vue.extend({
  name: 'SiteNavGroupSubnav',
  components: { SiteNavGroupBack, SiteNavGroupHeader },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
  },
})
