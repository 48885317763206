
import Vue, { PropType } from 'vue'
import { routeMatch } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'NavLink',
  props: {
    activeClasses: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    exact: {
      type: Boolean,
      default: false,
    },
    addedActiveMatch: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    excludeActiveMatch: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
  },
  methods: {
    click(navigate: (event: Event) => void, event: Event) {
      this.$emit('click')
      navigate(event)
    },
    classes(isActive: boolean, isExactActive: boolean): string {
      if (routeMatch(this, this.excludeActiveMatch)) {
        return ''
      }
      const additional = routeMatch(this, this.addedActiveMatch)
      if (!isActive && !additional) return ''
      let c = isExactActive
        ? 'nuxt-link-active nuxt-link-exact-active'
        : 'nuxt-link-active'
      if (this.activeClasses) {
        if (isExactActive || !this.exact || additional) {
          c += ` ${this.activeClasses}`
        }
      }
      return c
    },
  },
})
