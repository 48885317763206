
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { DjangoUrl } from '~/assets/ts/utils/dashboard'
import { broadcasterSignInUrl, webcastRootUrl } from '~/assets/ts/utils/urls'
import { User } from '~/models/users/user'
import { toCurrency } from '~/assets/ts/utils/math'
import { BroadcasterSignupInfo } from '~/models/broadcasterSignup'
import SiteNavBaseTemplate from '~/components/site/nav/BaseTemplate.vue'
import SiteNavLi from '~/components/site/nav/Li.vue'
import FoldedRibbon from '~/components/_general/FoldedRibbon.vue'
import nav from '~/components/event/Nav.vue'
import { daysBetweenDates } from '~/assets/ts/utils/date'

export default Vue.extend({
  name: 'SiteNavBase',
  components: { FoldedRibbon, SiteNavLi, SiteNavBaseTemplate },
  computed: {
    nav() {
      return nav
    },
    user(): User | undefined {
      return this.$users.user
    },
    broadcasterCount(): number {
      if (!this.user) return 0
      return this.user.broadcasterRoles.length
    },
    singleBroadcaster(): string | undefined {
      if (this.user?.admin) return undefined
      return this.broadcasterCount === 1
        ? this.user?.broadcasterRoles[0].broadcasterID
        : undefined
    },
    webcastsUrl(): string {
      return webcastRootUrl
    },
    becomeBroadcasterUrl(): string {
      return DjangoUrl('/broadcaster/overview')
    },
    broadcasterSignInUrl(): string {
      return broadcasterSignInUrl
    },
    sale(): boolean {
      return this.signupInfo?.sale
    },
    saleText(): TranslateResult {
      if (!this.sale) return ''
      const dollars = toCurrency(this.signupInfo.signupFee)
      const dayCount = daysBetweenDates(new Date(), this.signupInfo.endDate)
      if (dayCount > 14) {
        return this.$t('{dollars} Signup Sale', { dollars })
      }
      const sale = this.$t('{dollars} Sale', { dollars })
      const days =
        dayCount < 2
          ? this.$t('Last Day')
          : this.$tc('{n} day | {n} days', dayCount, { n: dayCount })
      return `${sale} // ${days}`
    },
    signupInfo(): BroadcasterSignupInfo {
      return new BroadcasterSignupInfo(
        this.$store.getters['broadcastersignup/signupInfo']
      )
    },
  },
  async mounted() {
    const signupInfo = await this.$apiClient.getSignupInfo()
    this.$store.commit(
      'broadcastersignup/ADD_BROADCASTER_SIGNUP_INFO',
      signupInfo
    )
  },
  methods: {
    navigate() {
      this.$emit('navigate')
    },
  },
})
