
import Vue, { PropType } from 'vue'
import { SiteNavGroup } from './Contents.vue'
import { DashboardNavGroup } from '~/components/dashboard/nav/Contents.vue'
import CustomScroll from '~/components/_general/CustomScroll.vue'

export default Vue.extend({
  name: 'SiteNavTemplate',
  components: { CustomScroll },
  props: {
    defaultGroup: {
      type: Number as PropType<SiteNavGroup | DashboardNavGroup>,
      default: 0,
    },
  },
  data() {
    return {
      currentGroup: this.defaultGroup as SiteNavGroup | DashboardNavGroup,
    }
  },
  methods: {
    centeredClass(group: SiteNavGroup | DashboardNavGroup): string {
      if (group === this.currentGroup) return 'group-center'
      return group === this.defaultGroup ? 'group-left' : 'group-right'
    },
    navigate() {
      // put this back if we want sub navs to go back to home on navigation
      // await wait(300)
      // this.setGroup(NavGroup.Home)
    },
    setGroup(group: SiteNavGroup | DashboardNavGroup) {
      this.currentGroup = group
      const container = (this.$refs.container as Vue).$el
      container.scroll(0, 0)
    },
    back() {
      this.setGroup(this.defaultGroup)
    },
  },
})
