export const TextInputDefaults = 'text-sm text-input-theme'

export const BroadcasterNameAutocomplete = {
  name: 'company',
  autocomplete: 'organization',
}

export const BroadcasterDisplayNameDefaults = {
  minlength: 2,
  maxlength: 255,
  required: true,
  placeholder: 'e.g. Faith Free Presbyterian Church',
  class: TextInputDefaults,
  ...BroadcasterNameAutocomplete,
}
export const BroadcasterShortNameDefaults = {
  ...BroadcasterDisplayNameDefaults,
  maxlength: 30,
  required: false,
  placeholder: 'e.g. Faith FPC',
}

export const BroadcasterDisplayLocationDefaults = {
  minlength: 2,
  maxlength: 255,
  required: true,
  placeholder: 'e.g. Greenville, South Carolina',
  class: TextInputDefaults,
  name: 'company',
  autocomplete: 'organization',
}

export const BroadcasterIDDefaults = {
  minlength: 3,
  maxlength: 20,
  class: TextInputDefaults,
  autocapitalize: 'none',
  autocomplete: 'broadcaster-id',
  name: 'broadcaster-id',
  pattern: '[a-z0-9]{3,20}' as string | undefined,
}

export function BroadcasterShortNameRequired(displayName: string) {
  return displayName.length > BroadcasterShortNameDefaults.maxlength
}

export const WebsiteFieldDefaults = {
  placeholder: 'https://example.com',
  class: TextInputDefaults,
  maxlength: 255,
  type: 'url',
}

export const ImageUrlFieldDefaults = {
  class: TextInputDefaults,
  placeholder: 'https://example.com/image.jpg',
  type: 'url',
}

export const PhoneNumberDefaults = {
  placeholder: '(555) 123-4567',
  class: TextInputDefaults,
  type: 'tel',
  name: 'phone mobile',
  autocomplete: 'tel',
}

export const EmailDefaults = {
  placeholder: 'email@example.com',
  class: TextInputDefaults,
  required: true,
  type: 'email',
  name: 'email',
  autocomplete: 'email',
}

export const UsernameDefaults = {
  class: TextInputDefaults,
  required: true,
  autofocus: true,
  minlength: 4,
  name: 'username',
  autocomplete: 'username',
}

export const DisplayNameDefaults = {
  class: TextInputDefaults,
  name: 'display_name',
  autocomplete: 'nickname',
}

export const FullNameDefaults = {
  class: TextInputDefaults,
  name: 'name',
  autocomplete: 'name',
}

export const PasswordDefaults = {
  class: TextInputDefaults,
  name: 'password',
  autocomplete: 'current-password',
  required: true,
  minlength: 8,
  type: 'password',
}

export const NewPasswordDefaults = {
  ...PasswordDefaults,
  name: 'new-password',
  autocomplete: 'new-password',
}

// const AutocompleteInfo = {
//   Name: {
//     name: 'name',
//     autocomplete: 'name',
//   },
//   FirstName: {
//     name: 'first_name',
//     autocomplete: 'given-name',
//   },
//   LastName: {
//     name: 'last_name',
//     autocomplete: 'family-name',
//   },
//   DisplayName: {
//     name: 'display_name',
//     autocomplete: 'nickname',
//   },
//   Username: {
//     name: 'username',
//     autocomplete: 'username',
//   },
//   Honorific: {
//     name: 'honorific',
//     autocomplete: 'honorific-prefix',
//   },
//   Email: {
//     name: 'email',
//     autocomplete: 'email',
//   },
//   Phone: {
//     name: 'phone mobile',
//     autocomplete: 'tel',
//   },
//   Company: {
//     name: 'company',
//     autocomplete: 'organization',
//   },
//   Street: {
//     name: 'address',
//     autocomplete: 'address street-address',
//   },
//   City: {
//     name: 'city',
//     autocomplete: 'city locality address-level2',
//   },
//   State: {
//     name: 'region state',
//     autocomplete: 'address-level1 region province',
//   },
//   Zipcode: {
//     name: 'zip postal',
//     autocomplete: 'postal-code',
//   },
//   Country: {
//     name: 'country',
//     autocomplete: 'country',
//   },
//   Password: {
//     name: 'password',
//     autocomplete: 'current-password',
//   },
//   NewPassword: {
//     name: 'new-password',
//     autocomplete: 'new-password',
//   },
// }

export function InputValue(event: Event) {
  return (event.target as HTMLInputElement).value
}
