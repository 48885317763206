// noinspection GrazieInspection

import { TranslateResult } from 'vue-i18n'
import { apiSignupInfo } from '~/apiclient/apibroadcastersignup'
import { apiCouponInfo, CouponTypes } from '~/apiclient/apicoupons'
import { Coupon } from '~/models/coupons'
import { toDollars } from '~/assets/ts/utils/math'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'

export class BroadcasterSignupInfo {
  coupon?: Coupon
  signupFee: number
  endDate: Date
  sale: boolean
  regularSignupFee: number
  monthlyFee: number
  discountMonths = 0
  discountCost?: number

  constructor(
    info: apiSignupInfo,
    coupon: apiCouponInfo | undefined = undefined
  ) {
    this.sale = info.saleInProgress
    this.regularSignupFee = info.signupFee
    this.signupFee = this.sale ? info.saleSignupFee : this.regularSignupFee
    this.monthlyFee = info.monthlyFee
    this.endDate = apiTimestampToJsDate(info.saleEndDate)

    if (!coupon) return
    const c = new Coupon(coupon)
    if (!c.IsUsableSignupCoupon) return
    this.coupon = c

    if (this.coupon.IsDiscountCoupon) {
      this.discountMonths = this.coupon.adjustmentDurationMonths ?? 0
      this.discountCost = this.coupon.monthlyFeeAdjustment
    }
    const couponFee = this.coupon.SignupFee
    if (couponFee !== undefined) {
      this.signupFee = Math.min(couponFee, this.signupFee)
    }
  }

  get MonthsFree(): number {
    if (!this.coupon) return 1
    switch (this.coupon.type) {
      case CouponTypes.SignupRepeat1YearFree:
        return 12
      case CouponTypes.SignupRepeat6MonthsFree:
        return 6
      case CouponTypes.SignupRepeat3MonthsFree:
        return 3
      default:
        return 1
    }
  }

  get FreeSignup(): boolean {
    return this.signupFee <= 0
  }

  get SignupFeeDollars(): string {
    return toDollars(this.signupFee * 100)
  }

  get DiscountCostDollars(): string {
    if (!this.discountCost) return ''
    return toDollars(this.discountCost * 100)
  }

  get MonthlyFeeDollars(): string {
    return toDollars(this.monthlyFee * 100)
  }

  get RegularSignupDollars(): string {
    return toDollars(this.regularSignupFee * 100)
  }

  GetSignupText(context: Record<string, any>): TranslateResult {
    let text: TranslateResult = ''
    if (this.coupon) {
      text = `${context.$t('Coupon Applied')}: `
    }
    if (this.coupon && this.discountMonths) {
      const months = context.$tc(
        '{n} month | the first {n} months',
        this.discountMonths
      )
      text += context.$t('Sign up and get {months} for only {rate}/mo!', {
        months,
        rate: this.DiscountCostDollars,
      })
    } else if (this.coupon && this.MonthsFree > 1) {
      text += context.$t('Get {n} months free!', { n: this.MonthsFree })
    } else if (this.coupon && this.FreeSignup) {
      text += context.$t('Sign up for free!')
      // Apparently no longer needed
      // } else if (this.coupon && !this.FreeSignup) {
      //   text += context.$t('Sign up for only {fee}!', {
      //     fee: this.SignupFeeDollars,
      //   })
    } else if (this.sale && !this.FreeSignup) {
      text += context.$t('Sign up for only {fee} for a limited time!', {
        fee: this.SignupFeeDollars,
      })
      // Apparently no longer needed
      // } else if (this.sale && this.FreeSignup) {
      //   text += context.$t('Sign up for free for a limited time!')
    }
    return text
  }
}
