
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import EventNavItem from '~/components/event/NavItem.vue'
import WebcastPing from '~/components/_general/WebcastPing.vue'
import EventRegisterButton from '~/components/event/RegisterButton.vue'

export default Vue.extend({
  name: 'EventNav',
  components: { EventRegisterButton, WebcastPing, EventNavItem },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
  },
  computed: {
    broadcasterId(): string {
      return this.event.broadcaster.id
    },
    webcastInProgress(): boolean {
      if (!this.event.inProgress()) return false
      return this.$store.getters['webcasts/broadcasterLive'](this.broadcasterId)
    },
  },
  methods: {
    register() {
      this.$emit('register')
      this.navigated()
    },
    navigated() {
      this.$emit('navigated')
    },
  },
})
