
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SaLink from '~/components/_general/SaLink.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteHeaderLogo',
  components: { SaIcon, SaLink },
  props: {
    url: {
      type: String,
      default: '/',
    },
    beta: {
      type: Boolean,
    },
    subtitle: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
  },
})
