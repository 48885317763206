
import Vue from 'vue'
import { scrollTo } from '~/assets/ts/utils/misc'

export default Vue.extend({
  name: 'EventNavItem',
  props: {
    hash: {
      type: String,
      default: '',
    },
    hashInUrl: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hashValue(): string {
      return this.hashInUrl ? `#${this.hash}` : ''
    },
  },
  methods: {
    clicked() {
      this.$emit('nav')
      const url = `${this.$route.path}${this.hashValue}`
      history.pushState({}, '', url)
      scrollTo(this.hash)
    },
  },
})
