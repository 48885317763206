
import Vue from 'vue'

export default Vue.extend({
  name: 'WebcastPing',
  props: {
    animate: {
      type: Boolean,
      default: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
    delay: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    indicatorVariables(): Record<string, string> {
      return {
        '--ping-duration': `${this.duration}s`,
        '--indicator-scale': `${this.scale * 0.6}`,
        '--ping-delay': `${this.delay}s`,
      }
    },
  },
})
