
import Vue from 'vue'

export default Vue.extend({
  name: 'FoldedRibbon',
  props: {
    ribbonPadding: {
      type: String,
      default: '8px',
    },
    ribbonWidth: {
      type: String,
      default: '6px',
    },
    paddingY: {
      type: String,
      default: '3px',
    },
    foldHeight: {
      type: String,
      default: '6px',
    },
  },
})
