var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"overflow-hidden flex justify-between items-center py-0 pl-0",class:[
    _vm.rounded ? 'rounded-md' : '',
    _vm.disabled ? 'disabled' : '',
    _vm.focus ? 'focus' : '',
    _vm.border ? 'border' : 'border-none',
    _vm.useTheme ? 'text-input-theme' : '',
    _vm.backgroundThemeColor ? 'text-input-highlight' : '',
  ]},[_c('input',{ref:"input",class:_vm.inputClasses,attrs:{"type":"text","autocapitalize":"off","disabled":_vm.disabled,"autofocus":_vm.autofocus,"placeholder":_vm.placeholder.toString()},domProps:{"value":_vm.input},on:{"focusin":function($event){_vm.focus = true},"focusout":function($event){_vm.focus = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.trySearch.apply(null, arguments)},"input":function($event){$event.preventDefault();_vm.$emit('input', _vm.InputValue($event))}}}),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasInput),expression:"hasInput"}],staticClass:"cursor-pointer px-3 clear-button",class:[
      _vm.useTheme ? 'text-theme-blue border-theme' : 'text-blue border-light',
      _vm.isLocalSearching ? '' : 'border-r',
    ],attrs:{"type":"button"},on:{"click":_vm.clear}},[_c('SaIcon',{attrs:{"name":"close"}})],1),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLocalSearching),expression:"!isLocalSearching"}],staticClass:"search-button cursor-pointer px-4",class:[
      _vm.minInput
        ? _vm.useTheme
          ? 'text-gray-600 dark:text-gray-200'
          : 'enabled'
        : _vm.useTheme
          ? 'text-gray dark:text-gray-600'
          : 'disabled',
    ],attrs:{"type":"button","name":"Search"},on:{"click":_vm.trySearch}},[(!_vm.searching)?_c('SaIcon',{attrs:{"name":"search"}}):_c('Spinner')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }