
import Vue from 'vue'
import SiteNavGroupSubnav from '~/components/site/nav/group/Subnav.vue'
import SiteNavLi from '~/components/site/nav/Li.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

export default Vue.extend({
  name: 'SiteNavGroupAdmin',
  components: { HorizontalRule, SiteNavLi, SiteNavGroupSubnav },
  methods: {
    navigate() {
      this.$emit('navigate')
    },
  },
})
