
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { openPopoutWindow } from '~/assets/ts/utils/misc'
import { RadioStreamID } from '~/apiclient/apiradio'
import SiteNavGroupSubnav from '~/components/site/nav/group/Subnav.vue'
import SaImage from '~/components/_general/SaImage.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'

interface SidebarRadioItem {
  logoUrl: string
  backgroundUrl?: string
  backgroundSize?: number
  backgroundColor: string
  title: TranslateResult
  siteUrl?: string
  externalUrl?: string
}

export default Vue.extend({
  name: 'SiteNavGroupRadio',
  components: { InlineIcon, SaImage, SiteNavGroupSubnav },
  props: {
    active: {
      type: Boolean,
    },
  },
  data() {
    return {
      fetched: false,
      vcyRadio: {
        logoUrl: 'radio/logo_vcy.png',
        backgroundColor: 'white',
        title: this.$t('VCY America Radio'),
        externalUrl: 'http://www.vcyamerica.org/listen/',
      } as SidebarRadioItem,
      radioItems: [
        {
          logoUrl: 'radio/logo_sa.png',
          backgroundColor: '#2a2a2a',
          backgroundSize: 26,
          backgroundUrl: 'radio/bg_sa_lighter.png',
          title: this.$t('SA Radio Stream'),
          siteUrl: '/radio/',
        },
      ] as SidebarRadioItem[],
    }
  },
  watch: {
    active() {
      if (this.fetched || !this.active) return
      this.getRadioStreams()
    },
  },
  methods: {
    async getRadioStreams() {
      this.fetched = true
      const { results } = await this.$apiClient.getRadioStreams()
      results.forEach((r) => {
        if (r.radioStreamID === RadioStreamID.VCY) {
          this.radioItems.push(this.vcyRadio)
        }
      })
    },
    url(radio: SidebarRadioItem): string {
      return (radio.siteUrl ?? radio.externalUrl) as string
    },
    openPopout(radio: SidebarRadioItem) {
      openPopoutWindow(this.url(radio))
      this.$emit('navigate')
    },
    bgStyle(radio: SidebarRadioItem): string {
      if (!radio.backgroundUrl) return ''
      // https://v0.image.nuxtjs.org/api/dollarimg
      const $img = (this as any).$img
      const url = $img(radio.backgroundUrl, {
        width: radio.backgroundSize,
        quality: 70,
      })
      return radio.backgroundUrl
        ? `background-image: url(${url}); background-size: ${radio.backgroundSize}px`
        : ''
    },
  },
})
