
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'

export default Vue.extend({
  name: 'EventRegisterButton',
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    openClasses: {
      type: String,
      default: '',
    },
    closedClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    closed(): boolean {
      return this.event.registrationClosed
    },
  },
  methods: {
    click() {
      if (this.closed) return
      this.$emit('register')
    },
  },
})
