import { apiCouponInfo, CouponTypes } from '~/apiclient/apicoupons'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'

export class Coupon {
  id: string
  broadcasterId?: string
  eventId?: string
  type: CouponTypes
  issueDate?: Date
  expirationDate?: Date
  usedDate?: Date

  adjustmentDurationMonths?: number
  monthlyFeeAdjustment?: number
  monthlyFeeReducedByAmount?: number

  constructor(apiInfo: apiCouponInfo) {
    this.id = apiInfo.couponID
    this.broadcasterId = apiInfo.broadcasterID || undefined
    this.eventId = apiInfo.eventID || undefined
    this.type = apiInfo.couponType
    this.issueDate = apiInfo.dateIssued
      ? apiTimestampToJsDate(apiInfo.dateIssued)
      : undefined
    this.expirationDate = apiInfo.expirationDate
      ? apiTimestampToJsDate(apiInfo.expirationDate)
      : undefined
    this.usedDate = apiInfo.dateUsed
      ? apiTimestampToJsDate(apiInfo.dateUsed)
      : undefined

    this.adjustmentDurationMonths = apiInfo.adjustmentDurationMonths
    this.monthlyFeeAdjustment = apiInfo.monthlyFeeAdjustment
    this.monthlyFeeReducedByAmount = apiInfo.monthlyFeeReducedByAmount
  }

  get FreeEvent(): boolean {
    return this.type === CouponTypes.EventFreeRegistration
  }

  get IsUsableSignupCoupon(): boolean {
    const repeatable = [
      CouponTypes.SignupReferralRepeat,
      CouponTypes.SignupRepeat,
      CouponTypes.SignupRepeat3MonthsFree,
      CouponTypes.SignupRepeat6MonthsFree,
      CouponTypes.SignupRepeat1YearFree,
    ].includes(this.type)
    if (repeatable) return true
    if (this.usedDate) return false
    return [
      CouponTypes.FreeSignup,
      CouponTypes.Signup,
      CouponTypes.SignupReferral,
      CouponTypes.SignupLowerMonthly1Y10USD,
      CouponTypes.SignupLowerMonthly1Y1USD,
    ].includes(this.type)
  }

  get IsDiscountCoupon(): boolean {
    return [
      CouponTypes.SignupLowerMonthly1Y1USD,
      CouponTypes.SignupLowerMonthly1Y10USD,
    ].includes(this.type)
  }

  get FreeSignup(): boolean {
    return [
      CouponTypes.SignupRepeat3MonthsFree,
      CouponTypes.SignupRepeat6MonthsFree,
      CouponTypes.SignupRepeat1YearFree,
      CouponTypes.SignupReferral,
      CouponTypes.SignupReferralRepeat,
      CouponTypes.FreeSignup,
    ].includes(this.type)
  }

  get OneDollarSignup(): boolean {
    return [
      CouponTypes.Signup,
      CouponTypes.SignupRepeat,
      CouponTypes.SignupLowerMonthly1Y1USD,
      CouponTypes.SignupLowerMonthly1Y10USD,
    ].includes(this.type)
  }

  get SignupFee(): number | undefined {
    if (this.FreeSignup) return 0
    if (this.OneDollarSignup) return 1
    return undefined
  }
}
